<template>
  <div class="website-external-wrapper" v-if="siteContent && slug && siteContent[slug]">
    <PassBox
      v-if="siteContent[slug].fields.password"
      :challenge="siteContent[slug].fields.password"
    ></PassBox>
    <AppHeader
      :data="siteContent"
      :slug="slug"
      :key="'app-header'+$route.fullPath"
      v-if="!siteContent[slug].fields.hideInMenu"
    ></AppHeader>
    <AppHeaderReduced
      :data="siteContent"
      :slug="slug"
      :key="'app-header-reduced'+$route.fullPath"
      v-if="siteContent[slug].fields.hideInMenu && siteContent[slug].fields.showSensataLogo && !siteIsOfflineApp"
    ></AppHeaderReduced>
    <AppHeaderReducedWithOfflineLink
      :data="siteContent"
      :slug="slug"
      :key="'app-header-reduced'+$route.fullPath"
      v-if="siteContent[slug].fields.hideInMenu && siteContent[slug].fields.showSensataLogo && siteIsOfflineApp"
    ></AppHeaderReducedWithOfflineLink>
    <WebContainer
      :data="siteContent"
      :slug="slug"
      v-if="siteContent && siteContent[slug] && !siteContent[slug].fields.isBlog"
      :key="Math.random() + $route.fullPath"
    ></WebContainer>
    <WebArchive
      :data="siteContent"
      :slug="slug"
      v-if="siteContent && siteContent[slug] && siteContent[slug].fields.isBlog"
      :key="Math.random() + $route.fullPath"
    ></WebArchive>
  </div>
</template>

<script>
export default {
  name: 'Web',

  data () {
    return {
      error: false,
      errMessage: '',
      spinner: false,
      dynamicContent: {}
    }
  },

  components: {
    AppHeader: () => import('Components/web/AppHeader'),
    PassBox: () => import('Components/web/PassBox'),
    AppHeaderReduced: () => import('Components/web/AppHeaderReduced'),
    AppHeaderReducedWithOfflineLink: () => import('Components/web/AppHeaderReducedWithOfflineLink'),
    WebContainer: () => import('Components/web/WebContainer'),
    WebArchive: () => import('Components/web/WebArchive')
  },
  computed: {
    siteIsOfflineApp () {
      return process.env.VUE_APP_BASE_URL.indexOf('https://app.sensataencuestas.com') !== 0 && process.env.VUE_APP_BASE_URL.indexOf('https://sensataencuestas.com') === 0 
    },
    siteId () {
      return process.env.VUE_APP_SITE_ID
    },
    siteContent () {
      return this.$store.state.site.content
    },
    totalPages () {
      var allPages = Object.keys(this.siteContent)
      return allPages.length
    },
    slug () {
      var root = '/'
      var path = this.$route.params.path
      var subpath = this.$route.params.subpath
      var subsubpath = this.$route.params.subsubpath
      if (path) {
        root = root + this.$route.params.path        
      }
      if (subpath) {
        if (subsubpath) {
          return root + '/' + subpath + '/' + subsubpath
        }
        return root + '/' + subpath
      }
      return root
    }
  },
  mounted () {
    if (this.totalPages) {
      document.dispatchEvent(new Event('render-completed'))
    }
    if (!localStorage.getItem('site_loaded')) {
      return
      this.$store.dispatch('LOAD_NEW_SITE', { siteId: this.siteId })
        .then(() => {
          localStorage.setItem('site_loaded', true)
        }).catch(err => {
          console.log('error loading site, ', err)
        })
    }
  },
  watch: {
    'totalPages': function (newVal) {
      if (newVal) {
        document.dispatchEvent(new Event('render-completed'))
      }
    },
    'slug': function (newVal) {
      if (!this.$store.state.site.content[this.slug]) {
        this.$router.push({ name: 'not-found' })
      }
    }
  },
  metaInfo () {
    var ogTitle = ''
    var ogDescription = ''
    var ogImage = ''

    var a = this.siteContent[this.slug]
    if (a && a.fields) {
      ogTitle = a.fields.ogTitle
      ogDescription = a.fields.ogDescription
      if (a.fields.ogImage) {
        ogImage = a.fields.ogImage.fields.file.url
      }
    }
    return {
      meta: [
        { vmid: "og:url", property: "og:url", content: `${process.env.VUE_APP_BASE_URL}${this.slug}` },
        { vmid: "description", name: "description", content: ogDescription },
        { vmid: "og:title", property: "og:title", content: ogTitle },
        { vmid: "og:description", property: "og:description", content: ogDescription },
        { vmid: "twitter:card", property: "twitter:card", content: "summary_large_image" },
        { vmid: "og:image:width", property: "og:image:width", content: "1200" },
        { vmid: "og:image:height", property: "og:image:height", content: "1200" },
        { vmid: "og:image", property: "og:image", content: 'https:' + ogImage }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.transition-visibility {
  transition: opacity 0.2s ease-in-out;
}
.invisible {
  opacity: 0.0001;
}
.action-wrapper {
  min-height: 120px;
  position: relative;

  .el-row {
    position: absolute;
    margin: 0 auto;
    width: 100%;
  }
}
</style>
