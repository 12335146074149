<template>
  <div
    v-if="page"
    class="question-wrapper page-wrapper view unselectable full-width"
    :class="{ 'scrollable': page && page.fields && page.fields.isScrollable }"
  >
    <transition
      :name="transitionName"
      tag="div"
      mode="in-out"
      class="full-area row-area desktop-wrapper"
      :class="{ 'scrollable': page.fields.isScrollable }"
    >
      <Screen
        :key="page.sys.id"
        :data="page"
        :fullWidth="true"
        v-if="page && page.sys.contentType.sys.id === 'screen'"
        v-on:next="next($event)"
        v-on:goto="goToURL($event)"
        v-on:previous="previous($event)"
      ></Screen>
    </transition>
  </div>
</template>

<script>

export default {
  props: [ 'bgImage' ],

  name: 'Page',

  components: {
    Screen: () => import('Components/questions/Screen')
  },

  data () {
    return {
      transitionName: 'slide'
    }
  },

  computed: {
    page () {
      return this.pages.find(item => item.fields.alias === this.alias)
    },
    pages () {
      return this.$store.state.site.pages
    },
    alias () {
      return this.$route.params.alias
    },
    sid () {
      return process.env.VUE_APP_DEFAULT_SID
    }
  },
  methods: {
    goToURL (url) {
      window.location.href = url
    },
    next () {
      this.transitionName = 'slideL'
      var current = this.pages.indexOf(this.page)
      if (current < this.pages.length - 1) {
        this.$router.push({ name: 'page', params: { 'alias': this.pages[current++].fields.alias } }).catch(err => {})
        this.$router.push(`/p/${this.pages[current++].fields.alias}`).catch(err => {})
      } else {
        this.$router.push({ name: 'survey', params: { sid: this.sid, qid: 0 } }).catch(err => {})
      }
    },
    previous () {
      this.transitionName = 'slideR'
      var current = this.pages.indexOf(this.page)
      if (current > 0) {
        this.$router.push({ name: 'page', params: { 'alias': this.pages[current--].fields.alias } }).catch(err => {})
        this.$router.push(`/p/${this.pages[current--].fields.alias}`).catch(err => {})
      } else {
        this.$router.push('/').catch(err => {})
      }
    }
  },
  mounted () {
    window.onpopstate = function () {
      this.transitionName = 'slideR'
    }
    setTimeout(async () => {
      document.dispatchEvent(new Event('render-completed'))
    }, 1000)
  }
}
</script>

<style scoped lang="scss">
.full-area,
.question-wrapper {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  &.scrollable {
    display: block;
  }
}

.full-area.scrollable {
  padding: 0 1em;
}

.desktop-wrapper {

}
</style>
