import Vue from 'vue'
import Router from 'vue-router'
import Home from 'Core/src/views/Home.vue'
import Survey from 'Core/src/views/Survey.vue'
import Submit from 'Core/src/views/Submit.vue'
import Web from 'Core/src/views/Web.vue'
import Results from 'Core/src/views/Results.vue'
import Profile from 'Core/src/views/Profile.vue'
import Page from 'Core/src/views/Page.vue'
import NotFound from 'Core/src/views/NotFound.vue'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,

  mode: 'history',

  routes: [
    {
      path: '/index.html',
      component: Home
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/p/:alias',
      name: 'page',
      component: Page
    },
    {
      path: '/s/:sid/:qid',
      name: 'survey',
      component: Survey
    },
    {
      path: '/s/:sid',
      name: 'surveyx',
      redirect: '/s/:sid/0'
    },
    {
      path: '/submit',
      name: 'submit',
      component: Submit
    },
    {
      path: '/results',
      name: 'results',
      component: Results
    },
    {
      path: '/profile/:pid',
      name: 'profile',
      component: Profile
    },
    {
      path: '/:path/:subpath/:subsubpath',
      name: 'web-inner-inner',
      component: Web,
      meta: {
        auth: true,
        title: 'Web'
      }
    },
    {
      path: '/:path/:subpath',
      name: 'web-inner',
      component: Web,
      meta: {
        auth: true,
        title: 'Web'
      }
    },
    {
      path: '/:path',
      name: 'web',
      component: Web
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound
    }
  ]
})

export default router
