<template>
  <div class="full-area vertically-centered desktop-wrapper">
    <h2>¡Ups, no encontrado!</h2>
    <el-row>
      <el-button
        type="danger"
        @click="reset()"
        class="submit-button is-rounded"
      >Comenzar de nuevo</el-button>
    </el-row>
    <div
      class="bot-404"
      :style="{backgroundImage: `url(${publicPath}/img/bot-404.jpg)`}"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  props: [ 'bgImage' ],

  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },

  methods: {
    reset () {
      localStorage.removeItem('sensata')
      this.$store.commit('SET_CURRENT', { current: 0 })
      this.$router.push({ name: 'home' })
    }
  },

  mounted () {
    setTimeout(async () => {
      document.dispatchEvent(new Event('render-completed'))
    }, 100)
  }
}
</script>

<style scoped lang="scss">
.desktop-wrapper {
  box-shadow: none;
}
.full-area {
  position: fixed;
  background-color: #F5F4F0;
  max-width: 100vw;
  width: 100vw;

  @include breakpoint($desktop) {
    max-height: 100vh;
  }
}
.bot-404 {
  width: 150px;
  height: 206px;
  background-size: cover;
  position: absolute;
  z-index: -1;
  bottom: 20px;
  right: 0;

  @include breakpoint($tablet) {
    width: 250px;
    height: 344px;
  }
}
</style>
