import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import AWS from 'aws-sdk'
import * as contentful from 'contentful'
Vue.use(VueAxios, axios)

// const contentful = require('contentful')
const client = contentful.createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_NAME,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
  retryOnError: true
})

const sensataEndpoint = `${process.env.VUE_APP_SURVEY_DATA_ENDPOINT}/surveys`
const sensataTrackerEndpoint = `${process.env.VUE_APP_SURVEY_DATA_ENDPOINT}/trackers`
const usersDBEndpoint = `${process.env.VUE_APP_SURVEY_DATA_ENDPOINT}/users`
const hubspotEndpoint = 'https://api.hsforms.com/submissions/v3/integration'

// const AWS = require('aws-sdk')

AWS.config.update({
  region: 'us-east-1',
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY
})

const s3 = new AWS.S3()

export default {
  fetchSite ({ siteId }) {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(siteId)
        .then(entry => {
          resolve(entry.fields)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchSiteFull ({ siteId }) {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(siteId, { include: 5 })
        .then(entry => {
          resolve(entry.fields)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchSurveyOneLevel ({ surveyId }) {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(surveyId, { include: 5 })
        .then(entry => {
          resolve(entry.fields)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchSurvey ({ surveyId }) {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(surveyId, { include: 10 })
        .then(entry => {
          resolve(entry.fields)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchTestResults ({ testResultsId }) {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(testResultsId)
        .then(entry => {
          resolve(entry.fields)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchProfile ({ profileId }) {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(profileId)
        .then(entry => {
          resolve(entry)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchSurveyRefs () {
    return new Promise((resolve, reject) => {
      return client
        .getEntry(process.env.VUE_APP_REFS_ID)
        .then(entry => {
          resolve(entry)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  fetchQuestion ({ something }) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (Math.random() > 0.95) {
          reject({ code: 500 })
        }
        resolve(something)
      }, 1000)
    })
  },
  requestSurveyToken ({ surveyId, fingerprint, surveyLength }) {
    return new Promise((resolve, reject) => {
      return Vue.axios.post(`${sensataEndpoint}/token`, {
        surveyId: surveyId,
        fingerprint: fingerprint,
        surveyLength: surveyLength
      })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },

  sendResponse ({ responses, surveyData, state }) {
    return new Promise((resolve, reject) => {
      var browserName = surveyData.browserReport.os ? surveyData.browserReport.os.name : 'undefined'
      var browserVersion = surveyData.browserReport.os ? surveyData.browserReport.os.version : 'undefined'
      var browserViewportWidth = surveyData.browserReport.viewport ? surveyData.browserReport.viewport.width : 'xx'
      var browserViewportHeight = surveyData.browserReport.viewport ? surveyData.browserReport.viewport.height : 'xx'
      var totalTime = 0

      // Calculate the total time it took from the second page (q1)
      responses.forEach((response, key) => {
        if (response && response.timeToCompletion && key !== 0) {
          totalTime += response.timeToCompletion
        }
      })

      return Vue.axios.post(sensataEndpoint, {
        appVersion: 'koku-3abref',
        sensataSecretKey: process.env.SENSATA_SECRET_KEY,
        captchaToken: state.survey.captchaToken,
        token: state.survey.token,
        dfp: state.dfp,
        browserOverview: `${state.survey.browserReport.ip} - ${browserName}/${browserVersion} - ${browserViewportWidth}x${browserViewportHeight}`,
        surveyName: state.survey.surveyName,
        surveyId: state.survey.surveyId,
        weightedTestResults: state.survey.weightedTestResults,
        params: state.survey.params,
        browserReport: state.survey.browserReport,
        totalTime: totalTime,
        totalTimeMin: Math.round((totalTime / 60000 + Number.EPSILON) * 100) / 100,
        // newResponses: state.newResponses,
        fingerprint: state.fingerprint,
        sensataId: `${state.fingerprint}_${state.survey.browserReport.ip}`,
        location: state.survey.location,
        geolocation: state.survey.geolocation,
        selfGeolocation: state.survey.selfGeolocation,
        geo: state.survey.geo,
        // fb: state.fbData,
        profile: state.survey.profile,
        // pausaActiva: state.pausaActiva,
        conjoint: state.conjoint,
        structuredResponses: state.structuredResponses,
        completedSurveys: state.completedSurveys,
        trialUser: state.trialUser

      })
        .then(data => {
          return resolve(data)
        })
        .catch(err => {
          console.log('errorx: ', err)
          return reject(err)
        })
    })
  },

  sendResponseToAltDB ({ response, params }) {
    return new Promise((resolve, reject) => {
      let r = response
      r['params'] = params
      return Vue.axios.put(`${sensataTrackerEndpoint}`, r)
        .then(data => {
          return resolve(data)
        })
        .catch(err => {
          console.log('errorx: ', err)
          return reject(err)
        })
    })
  },

  sendHubSpotForm ({ responses, surveyData }) {
    var fields = []

    responses.forEach(response => {
      if (response && response.hubSpotField) {
        var obj = {}
        obj['name'] = response.hubSpotField
        obj['value'] = response.selected.join(', ')
        fields.push(obj)
      }
    })
    return new Promise((resolve, reject) => {
      return Vue.axios.post(`${hubspotEndpoint}/submit/${surveyData.hubSpotUser}/${surveyData.hubSpotForm}`, JSON.stringify({
        fields: fields
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  identifyExistingUser ({ phoneId, recentSurveySetId, fingerprint, surveyId }) {
    // if no surveySet provided, register the user and do nothing else.
    return new Promise((resolve, reject) => {
      return Vue.axios.post(`${usersDBEndpoint}/identify`, {
        surveyId: surveyId,
        phoneId: phoneId,
        recentSurveySetId: recentSurveySetId,
        fingerprint: fingerprint
      })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          console.log('error: ', err)
          reject(err)
        })
    })
  },
  registerNewUser ({ data, survey, fingerprint }) {
    // if no surveySet provided, register the user and do nothing else.
    return new Promise((resolve, reject) => {
      return Vue.axios.post(`${usersDBEndpoint}/register`, {
        recentSurveySetId: survey.recentSurveySetId,
        firstName: data.name,
        lastName: data.lastname,
        phone: data.phone,
        phoneId: data.id,
        id: data.id,
        city: data.city,
        country: data.country,
        age: data.age,
        terms: data.terms,
        surveyId: survey.surveyId,
        fingerprint: fingerprint
      })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          console.log('error: ', err)
          reject(err)
        })
    })
  },

  uploadToS3 (file, bucketName, key, contentType) {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: bucketName,
        Key: key,
        Body: file,
        ContentType: contentType,
        ACL: 'public-read'
      }
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading to S3:', err)
          reject(err)
        } else {
          resolve(data.Location)
        }
      })
    })
  }
}
