import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

var rand = 'appx'
if (process.env.NODE_ENV === 'developmentx') {
  rand = Math.random()
}

const vuexPersist = new VuexPersist({
  key: `sensata_${rand}`,
  storage: localStorage,
  reducer: (state) => ({
    dfp: state.dfp,
    completed: state.completed,
    fingerprint: state.fingerprint,
    dataSet: state.dataSet,
    survey: state.survey,
    globalVars: state.globalVars,
    testResults: state.testResults,
    site: state.site,
    responses: state.responses,
    admin: state.admin,
    newResponses: state.newResponses,
    display: state.display,
    surveyFridge: state.surveyFridge,
    testResults: state.testResults,
    trialUser: state.trialUser,
    randomGroups: state.randomGroups,
    randomGroupsNavigator: state.randomGroupsNavigator,
    conjoint: state.conjoint,
    structuredResponses: state.structuredResponses,
    completedSurveys: state.completedSurveys
  })
})

export default new Vuex.Store({
  state: {
    dfp: '',
    fingerprint: '',
    completed: {},
    surveyFridge: {},
    displayQid: 0,
    display: {},
    dataSet: [],
    responses: [],
    structuredResponses: {},
    newResponses: {},
    surveyRefs: {},
    fbData: {},
    fbAuthResponse: {},
    globalVars: {},
    pausaActiva: [],
    conjoint: [],
    randomGroups: {},
    randomGroupsNavigator: {},
    survey: {
      qid: 0,
      allowOnlyOneResponse: false,
      ResultsPage: {},
      disallowBackButton: false,
      surveyName: '',
      questionCount: 4,
      current: 0,
      status: '',
      showTestResults: false,
      weightedTestResults: [],
      params: {},
      browserReport: {},
      customCTA: {},
      collectPersonalData: {},
      recentSurveySetId: '',
      backgroundImage: {},
      shortcodeTakeThisTest: '',
      language: 'ES',
      hubSpotForm: '',
      hubSpotUser: '',
      ogImage: {},
      ogTitle: '',
      ogDescription: '',
      location: {},
      geolocation: {},
      selfGeolocation: {},
      token: 'koku',
      captchaToken: '',
      geo: {},
      profile: '',
      requireSurvey: ''
    },
    testResults: [],
    completedSurveys: {},
    user: {
      profileId: null,
      profile: {},
      auth0: {},
      token: {},
      loggedIn: false
    },
    trialUser: {
      nickname: '',
      userId: '',
      more: {}
    },
    site: {
      pages: [],
      isLoaded: false,
      surveyIsLoaded: false,
      content: {},
      language: 'ES'
    },
    admin: {
      play: {
        status: '',
        startTimestamp: null,
        endTimestamp: null
      },
      sid: null,
      sidList: {}
    }
  },
  mutations,
  actions,
  plugins: [vuexPersist.plugin]
})
