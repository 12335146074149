<template>
  <div
    v-if="question && canRender"
    class="question-wrapper view"
  >
    <transition-group
      name="slide" tag="div" class="full-area row-area"
    >
      <Screen
        :key="'screen' + sid + question.sys.id"
        :data="question"
        v-if="question && question.sys.contentType.sys.id === 'screen' && canRender"
        v-on:fullscreen:off="fullscreenOff()"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Screen>
      <Bilderset
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'bilderset' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Bilderset>
      <Dilemma
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'dilemma' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Dilemma>
      <Thumbs
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'thumbs' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Thumbs>
      <Emoji
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'emoji' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Emoji>
      <Rating
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'rating' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Rating>
      <Slider
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'slider' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Slider>
      <DraggableSortList
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'draggableSortList' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></DraggableSortList>
      <Demographic
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'demographic' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Demographic>
      <TraditionalQuestion
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'traditionalQuestion' && canRender"
        v-on:next="next($event)"
        v-on:fullscreen:off="fullscreenOff()"
        v-on:previous="previous($event)"
      ></TraditionalQuestion>
      <LikertBubbles
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'likertBubbles' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></LikertBubbles>
      <NetPromoterScore
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'netPromoterScore' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></NetPromoterScore>
      <EmojiBubbles
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'emojiBubbles' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></EmojiBubbles>
      <MultiTextSelection
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'multiTextSelection' && canRender"
        v-on:previous="previous($event)"
        v-on:next="next($event)"
      ></MultiTextSelection>
      <EmojiSlider
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'emojiSlider' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></EmojiSlider>
      <QRReader
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'qrReader' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></QRReader>
      <Picture
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'picture' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Picture>
      <PinToMap
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'pinToMap' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></PinToMap>
      <PausaActiva
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'pausaActiva' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></PausaActiva>
      <Conjoint
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'conjoint' && canRender"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Conjoint>
      <RandomGroup
        :data="question"
        :key="sid + question.sys.id"
        :id="question.sys.id"
        v-if="question && question.sys.contentType.sys.id === 'randomGroup' && canRender"
        v-on:fullscreen="emitfullScreen()"
        v-on:next="next($event)"
        v-on:nextrg="nextrg($event)"
        v-on:previous="previous($event)"
      ></RandomGroup>
      <GoogleGeo key="google-geo" v-if="surveyInfo.collectGeolocation === 'Approximate'"></GoogleGeo>
    </transition-group>
  </div>
</template>

<script>
import Fingerprint2 from 'fingerprintjs2'

export default {
  props: [ 'bgImage' ],

  name: 'Survey',

  components: {
    RandomGroup: () => import('Components/questions/RandomGroup'),
    Screen: () => import('Components/questions/Screen'),
    Bilderset: () => import('Components/questions/Bilderset'),
    Dilemma: () => import('Components/questions/Dilemma'),
    Thumbs: () => import('Components/questions/Thumbs'),
    Emoji: () => import('Components/questions/Emoji'),
    Rating: () => import('Components/questions/Rating'),
    Slider: () => import('Components/questions/Slider'),
    DraggableSortList: () => import('Components/questions/DraggableSortList'),
    Demographic: () => import('Components/questions/Demographic'),
    LikertBubbles: () => import('Components/questions/LikertBubbles'),
    EmojiBubbles: () => import('Components/questions/EmojiBubbles'),
    MultiTextSelection: () => import('Components/questions/MultiTextSelection'),
    EmojiSlider: () => import('Components/questions/EmojiSlider'),
    Picture: () => import('Components/questions/Picture'),
    PinToMap: () => import('Components/questions/PinToMap'),
    TraditionalQuestion: () => import('Components/questions/TraditionalQuestion'),
    NetPromoterScore: () => import('Components/questions/NetPromoterScore'),
    PausaActiva: () => import('Components/questions/PausaActiva'),
    Conjoint: () => import('Components/questions/Conjoint'),
    QRReader: () => import('Components/questions/QRReader'),
    GoogleGeo: () => import('Components/utils/GoogleGeo'),
  },

  data () {
    return {
      idleTimer: null,
    }
  },

  methods: {
    emitfullScreen () {
      this.$emit('fullscreen')
    },
    previous () {
      this.$router.push({ params: { sid: this.sid, qid: this.current -  1 } })    
    },
    nextrg (params) {
      this.$router.push({
        name: 'survey-g',
        params: {
          sid: params.sid,
          qid: params.qid,
          rgqid: params.rgqid
        }
      })
    },
    next (data) {
      let response = {
        sensataId: `${this.$store.state.fingerprint}_${this.$store.state.survey.browserReport.ip}`,
        type: 'questionEvent',
        selected: data.selected,
        options: data.options,
        question: data.question,
        identifier: data.identifier,
        surveyId: this.sid,
        timestamp: new Date().getTime(),
        qid: parseInt(this.$router.currentRoute.params.qid, 10),
        // todo: add a way to prevent automated submissions
      }
      // send to parent iFrame
      window.parent.postMessage(JSON.stringify(response), '*')

      // send to alt DB for better tracking of abandonment rates
      if (data.type !== 'randomGroup') {
        this.$store.dispatch('SUBMIT_TO_ALT_TRACKING_DB', { response: response })
      }

      this.$emit('fullscreen')
      var skip = parseInt(data.skip, 10) || 0
      this.$store.dispatch('STORE_RESPONSE_NEW', { response: data, qid: parseInt(this.$router.currentRoute.params.qid, 10) })
      .then(() => {
        if (this.qid + 1 + skip < this.$store.state.survey.questionCount) {
          this.$store.dispatch('NEXT_QUESTION', {skip: skip})
        } else {
          // Request Google Captcha and forward to /submit
          this.$store.commit('SET_SURVEY_STATUS', { status: 'submit' })
          this.$router.push({ name: 'submit' })
        }
      })
    },
    pushRoute () {
      if (this.status === 'started') {
        this.$router.push({ 
          name: 'survey',
          params: { sid: this.sid, qid: this.current }
        }, () => {})
      } else {
        this.$router.push({ name: status, params: { sid: this.sid } }, () => {})
      }
    },

    restartIdleTimer () {
      return
      clearTimeout(this.idleTimer)
      var did = process.env.VUE_APP_DEFAULT_DISPLAY

      var timer = this.$store.state.responses.length ? 1000 * 60 * 1 : 1000 * 60 * 0.5

      this.idleTimer = setTimeout(() => {
        var redirectTo = localStorage.getItem('redirect_to_display')

        if (redirectTo) {
          localStorage.removeItem('redirect_to_display')
          return this.$router.push(redirectTo)
        }

        if (did) {
          localStorage.removeItem('redirect_to_display')
          return this.$router.push(`/display/${did}`)
        }
      }, timer)
    },
    fullscreenOff () {
      this.$emit('fullscreen:off')
    },
    async initialize () {
      // Store all the params that are included when retrieving the survey, and send them along with the survey
      var params = this.$route.query
      if (Object.keys(params).length) {
        this.$store.commit('STORE_REQUEST_PARAMS', { params: params })
      }

      // Wrong survey, clear and redirect
      if (this.$route.params.sid !== this.$store.state.survey.surveyId || typeof this.$route.params.qid === 'undefined' || this.$route.params.qid === '0') {
        // Clear params if different survey
        if (this.$route.params.sid !== this.$store.state.survey.surveyId) {
          this.$store.commit('STORE_REQUEST_PARAMS', { params: params })
        }
        this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.sid, force: true })
          .then(() => {
            this.$router.replace({ name: 'survey', params: { sid: this.sid, qid: 0 } }, () => {})
            this.$store.commit('CLEAR_RANDOM_GROUPS')

          })
      } else {
        // Survey perhaps started, but download latest version just in case
        if (this.$route.params.sid === this.$store.state.survey.surveyId && typeof this.$route.params.qid !== 'undefined') {
          this.$store.dispatch('RELOAD_SURVEY', { surveyId: this.sid })
        }
      }

      // Set fingerprint
      var that = this
      setTimeout(function () {
        Fingerprint2.get(components =>  {
          var values = components.map(function (component) { return component.value })
          var murmur = Fingerprint2.x64hash128(values.join(''), 31)
          that.$store.commit('SET_FINGERPRINT', { fingerprint: murmur })
        })
      }, 300)

      // Load HubSpot stuff
      setTimeout(() => {
        if (this.$store.state.survey && this.$store.state.survey.hubSpotUser && this.$store.state.survey.hubSpotForm) {
          let hubSpotTrackingCode = document.createElement('script')
          hubSpotTrackingCode.setAttribute('src', `https://js.hs-scripts.com/${this.$store.state.survey.hubSpotUser}.js`)
          document.head.appendChild(hubSpotTrackingCode)
        }

        // submit first record of interaction (page loaded)
        let response = {
          sensataId: `${this.$store.state.fingerprint}_${this.$store.state.survey.browserReport.ip}`,
          type: 'questionLoadedEvent',
          question: null,
          selected: [null],
          identifier: null,
          surveyId: this.sid,
          timestamp: new Date().getTime(),
          qid: parseInt(this.$router.currentRoute.params.qid, 10) - 1,
        }
        // send to alt DB for better tracking of abandonment rates
        this.$store.dispatch('SUBMIT_TO_ALT_TRACKING_DB', { response: response })
      }, 500)

      // Do not allow the user to load a question without answering the previous ones first.
      if (this.$store.state.responses && this.$store.state.responses.length < this.$route.params.qid && this.$store.state.responses) {
        this.$router.push({ name: 'survey', params: { sid: this.sid, qid: this.$store.state.responses.length } }, () => {})
      }
      this.$store.commit('SET_ACTUAL_QID', { qid: parseInt(this.qid, 10) })

    }
  },

  computed: {
    question () {
      return this.$store.state.dataSet[parseInt(this.$route.params.qid, 10)]
    },
    current () {
      return this.$store.state.survey.current
    },
    qid () {
      return this.$store.state.survey.qid
    },
    sid () {
      return this.$route.params.sid
    },
    submitted () {
      return this.$store.state.survey.submitted
    },
    status () {
      return this.$store.state.survey.status
    },
    surveyData () {
      return this.$store.state.dataSet
    },
    surveyInfo () {
      return this.$store.state.survey
    },
    trueSurveyName () {
      return this.$store.state.surveyFridge[this.$route.params.sid].surveyName
    },
    canRender () {
      return this.$store.state.survey.surveyName === this.trueSurveyName
    }
  },

  async mounted () {
    this.initialize()
  },

  beforeDestroy () {
    clearTimeout(this.idleTimer)
  },

  watch: {
    'current': function (newVal, oldVal) {
      this.pushRoute()

      // TODO
      // Improve this controller.
      // check qid and don't allow it to be higher than the number of responses... push route if that happens
      if (this.$store.state.survey.disallowBackButton) {
        if (this.current !== this.$store.state.responses.length + 1) {
          if (this.status === 'started') {
            this.$router.push({
              name: 'survey',
              params: {
                sid: this.sid,
                qid: this.$store.state.responses.length
              }
            }, () => {})
          } else {
            this.$router.push({ name: status })
          }
        }
      }
    },
    'qid': function (newVal) {
      if (newVal >= 1 && this.$store.state.survey.token === 'koku') {
        this.$store.dispatch('REQUEST_SURVEY_TOKEN', { surveyId: this.sid, fingerprint: this.$store.state.fingerprint})
        // add an error handler to this to notify the survey will be invalid, dialog to clear form
      }
      this.$store.commit('SET_ACTUAL_QID', { qid: parseInt(newVal, 10) })
      this.$store.commit('SET_CURRENT', { current: parseInt(newVal, 10) })
      this.restartIdleTimer()
    }
  },

  metaInfo () {
    var ogTitle = 'Juegos y opinión por Sensata UX Research!'
    var ogDescription = 'Pruebe su conocimiento y habilidades con juegos, tests y más.'
    var ogImage = 'https://sensata.tk/img/icons/android-chrome-512x512.png'
    var url = `${process.env.VUE_APP_BASE_URL}${window.location.pathname}`
    var fbAppID = process.env.VUE_APP_FACEBOOK_APP_ID

    var path = window.location.pathname.split('/')

    if (path.length === 4) {
      path.pop()
      url = `${process.env.VUE_APP_BASE_URL}${path.join('/')}/0`
    }

    var a = this.surveyInfo
    if (ogTitle && ogDescription) {
      ogTitle = a.ogTitle
      ogDescription = a.ogDescription
    }
    if (a.ogImage && a.ogImage.fields) {
      ogImage = a.ogImage.fields.file.url
    }
    return {
      meta: [
        { vmid: "og:url", property: "og:url", content: url },
        { vmid: "description", name: "description", content: ogDescription },
        { vmid: "og:title", property: "og:title", content: ogTitle },
        { vmid: "og:description", property: "og:description", content: ogDescription },
        { vmid: "twitter:card", property: "twitter:card", content: "summary_large_image" },
        { vmid: "og:image:width", property: "og:image:width", content: "1200" },
        { vmid: "og:image:height", property: "og:image:height", content: "1200" },
        { vmid: "og:image", property: "og:image", content: 'https:' + ogImage },
        { vmid: "fb:app_id", property: "fb:app_id", content: fbAppID },
        { vmid: "og:type", property: "og:type", content: 'website' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

.full-area,
.question-wrapper {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
</style>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>