<template>
  <div class="app-wrapper">
    <div id="app"
      :class="$route.name"
      :style="{backgroundImage: `url(${bgImage})`}"
    >
      <fullscreen
        ref="fullscreen"
        background="#f9f9f9"
        :wrap="false"
        class="desktop-wrapperx"
      >
        <transition name="page">
          <router-view
            v-on:fullscreen="goFullScreen()"
            v-on:fullscreen:off="turnOffFullScreen()"
            :class="{
              'full-area unselectable': $route.name === 'survey',
              'profile-page': $route.name === 'profile',
              'not-found': $route.name === 'not-found'
            }"
            v-if="!usePortrait"
            :bg-image="bgImage"
          />
        </transition>
        <transition name="page">
          <UsePortraitScreen
            class="view" v-if="usePortrait"
          ></UsePortraitScreen>
        </transition>
      </fullscreen>
    </div>
    <div class="shrug">
      <span class="super-big">¯\_(ツ)_/¯</span>
      Please use the portrait mode<br>
      Por favor gira el dispositivo
    </div>
  </div>
</template>

<script>
import 'browser-report'
import Vue from 'vue'
import AuthService from 'Core/src/auth/authService'

Vue.use(AuthService)
Vue.prototype.$eventHub = new Vue()

export default {
  name: 'app',

  data () {
    return {
      fullscreen: false,
      usePortrait: false,
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },

  computed: {
    siteId () {
      return process.env.VUE_APP_SITE_ID
    },
    routeName () {
      return this.$route.name
    },
    qid () {
      return this.$route.params.qid
    },
    bgImage () {
      if (this.$route.name === 'home' || this.$route.name === 'web' || this.$route.name === 'blog-outer' || this.$route.name === 'blog') {
        return ''
      }
      if (this.$store.state.survey.backgroundImage && this.$store.state.survey.backgroundImage.fields) {
        return this.$store.state.survey.backgroundImage.fields.file.url
      }
    },
    slug () {
      var root = '/'
      var path = this.$route.params.path
      var subpath = this.$route.params.subpath
      if (path) {
        root = root + this.$route.params.path        
      }
      if (subpath) {
        return root + '/' + subpath
      }
      return root
    }
  },

  components: {
    UsePortraitScreen: () => import('Components/base/UsePortraitScreen')
  },

  methods: {
    goFullScreen () {
      if (!this.fullscreen && this.$isMobile()) {
        this.$refs.fullscreen.toggle()
        this.fullscreen = true
      }
    },
    turnOffFullScreen () {
      if (this.fullscreen) {
        this.$refs.fullscreen.toggle()
        this.fullscreen = false
        this.wasFullscreen = true
      }
    },
    loadSite () {
      if (this.siteId && !this.$store.state.site.content.length) {
        this.$store.dispatch('LOAD_NEW_SITE', { siteId: this.siteId })
          .then(() => {
            localStorage.setItem('site_loaded', 'true')
          }).catch(err => {
            console.log('error loading site, ', err)
          })
      }
    },
    /*
     * Detect orientation and disable horizontal orientation for smartphones and small tablets
     */
    detectOrientation () {
      if (window.screen.height < window.screen.width && window.screen.height < 600 && !this.$route.name.includes('admin')) {
        this.usePortrait = true
      } else {
        this.usePortrait = false
      }
    }
  },

  mounted () {
    // We store the redirect_to_url var in localStorage
    /*
    var redirectTo = localStorage.getItem('redirect_to_url')
    if (redirectTo) { // ensure that no redirect happens if it's the same URL (home)
      localStorage.removeItem('redirect_to_url')
      setTimeout(() => {
        this.canRender = true
      }, 500)
      this.$router.push(redirectTo)
    } else {
      this.canRender = true
    }
  */

    /*
     * Add a browser report. TODO: check if there's a better package that works with the Brave browser.
     */
    // eslint-disable-next-line
    browserReport((err, report) => {
      if (err) {
        throw err
      }
      this.$store.commit('BROWSER_REPORT', { report: report })
    })

    /*
     * Add detectOrientation listener.
     */
    this.detectOrientation()
    window.addEventListener('resize', this.detectOrientation)

    /*
     * Load website on mounted.
     */
    this.loadSite()

    // detect user's location or browser language and redirect to the appropriate language
    var preferredLang = localStorage.getItem('preferred_lang')

    // if the user has changed the language at least once, use that preference
    /*
    var entryPage = this.$store.state.site.content[this.slug]
    if (entryPage && entryPage.fields) {
      if (preferredLang && entryPage.fields.translationUrl) {
        if (preferredLang === 'ES' && this.slug.indexOf('/es') !== 0 || preferredLang === 'EN' && this.slug.indexOf('/es') === 0) {
          this.$router.push(entryPage.fields.translationUrl)
        }
      } else {
        if (!preferredLang) {
          return
          // Redirect only the home page
          if (this.slug === '/' || this.slug === '/es') {
            if (this.$store.state.survey.browserReport.lang && this.$store.state.survey.browserReport.lang.length) {
              if (['es-CO', 'es-ES', 'es-PE', 'es-MX', 'es-AR', 'es-CL', 'es-VE', 'es-DO'].includes(this.$store.state.survey.browserReport.lang[0])) {
                this.$router.push('/es')
              } else {
                this.$router.push('/')
              }
            }
          }
        }
      }
    }
    */

    return

  },

  beforeDestroy () {
    window.removeEventListener('resize', this.detectOrientation)
  },
  watch: {
    'qid': function (newVal) {
      this.$store.commit('SET_ACTUAL_QID', { qid: parseInt(newVal, 10) })
      this.$store.commit('SET_CURRENT', { current: parseInt(newVal, 10) })
    },
    '$route' (to, from) {
      if (this.$store.state.site.content && this.$store.state.site.content[to.fullPath])
      document.title = this.$store.state.site.content[to.fullPath].fields.ogTitle
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  font-family: 'Avenir', 'Nunito', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  min-width: 100vw;

  &.not-found {
    background-color: #F5F4F0;
    height: 100vh;
    display: flex;
  }
}

.app-wrapper {
  min-height: 100vh;
}

.full-area {
  justify-content: center !important;
  &.not-found {
    background-color: #F5F4F0;
  }
}
.shrug {
  z-index: -10000;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  position: fixed;
  display: none;
  text-align: center;
}

@media only screen and (min-device-aspect-ratio:3/2) and (max-width: 1024px) and (orientation: landscape) {
  html {
    #app {
      display: none;
    }
    .shrug {
      display: block;
      opacity: 1;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $color-wrapper-emphasis-lighter;
      z-index: 10000;
      color: white;
      font-size: 20px;
      .super-big {
        font-size: 50px;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

</style>
