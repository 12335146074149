<template>
  <div class="full-area padded vertically-centered desktop-wrapperx">
    <h3 class="no-connection-banner delay-entry" v-if="displayConnectionError">{{connectionErrorText}}</h3>
    <h2>{{processingFormText}}</h2>
    <div class="action-wrapper">
      <el-row class="transition-visibility" :class="{ 'invisible': !spinner }">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </el-row>
      <el-row class="transition-visibility" :class="{ 'invisible': !error }">
        <p class="error-wrapper">{{errorOccurredText}} <br>{{errMessage}}</p>
        <div v-if="retry">
          <el-button
            class="button-wrapper"
            type="danger"
            round @click="preSubmit()"
          >{{processAgainText}}</el-button>
        </div>
        <div v-if="!retry">
          <el-button
            class="button-wrapper"
            type="danger"
            round @click="goHome()"
          >{{goHomeInstead}}</el-button>
        </div>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Submit',

  data () {
    return {
      error: false,
      errMessage: '',
      spinner: false,
      retry: true,
      displayConnectionError: false
    }
  },

  computed: {
    connectionErrorText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Network error, check your connection.'
      }
      return 'Error de conexión (status: offline)'
    },
    goHomeInstead () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Go to the front page'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Comece de novo'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'छोड़ना'
      }
      return 'Ir al inicio'
    },
    processingFormText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Processing...'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Processando...'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'प्रसंस्करण...'
      }
      return 'Procesando...'
    },
    processAgainText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Try again'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Em processo'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'पुनः प्रयास करें'
      }
      return 'Procesar de nuevo'
    },
    errorOccurredText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'An error has occurred: '
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Ocorreu um erro: '
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'एक गलती हुई है: '
      }
      return 'Ocurrió un error: '
    },
    profiles () {
      return this.$store.state.survey.testResults
    },
    weightedTestResults () {
      return this.$store.state.survey.weightedTestResults
    }
  },

  mounted () {
    if (this.$store.state.survey.status !== 'submit') {
      this.$router.push('/')
    } else {
      this.preSubmit()
    }
  },

  methods: {
    preSubmit () {
      this.spinner = true
      this.displayConnectionError = false
      this.retry = false
      this.error = false
      // calculate weightedVector
      const responses = this.$store.state.responses
      var vector = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var weightedMin = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var weightedMax = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      responses.forEach(response => {
        if (!response || !response.weighted || response.weighted.length === 0) {
          return
        }
        response.weighted.forEach((score, index) => {
          if (!isNaN(score)) {
            vector[index] = parseInt(vector[index], 10) + parseInt(score, 10)
          }
        })
        response.weightedMax.forEach((thisDimension, index) => {
          if (!isNaN(thisDimension)) {
            weightedMax[index] = parseInt(weightedMax[index], 10) + parseInt(thisDimension, 10)
          }
        })
        response.weightedMin.forEach((thisDimension, index) => {
          if (!isNaN(thisDimension)) {
            weightedMin[index] = parseInt(weightedMin[index], 10) + parseInt(thisDimension, 10)
          }
        })
      })

      var newVector = []
      vector.forEach((dimension, index) => {
        if (weightedMax[index] !== weightedMin[index]) {
          newVector.push(Math.floor(100 * (dimension / (weightedMax[index] - weightedMin[index]) - weightedMin[index] / (weightedMax[index] - weightedMin[index])), 1))
        } else {
          newVector.push(50)
        }
      })
      this.$store.commit('STORE_TEST_RESULT', { weightedTestResults: newVector })

      // edit the weightedTestResult if there's a slider that needs to be savwed without balancing
      this.$store.commit('ALTER_WEIGHTED_TEST_RESULTS')


      if (this.$store.state.survey.testResults && this.$store.state.survey.testResults.sys) {
        this.$store.dispatch('LOAD_TEST_RESULTS', { testResultsId: this.$store.state.survey.testResults.sys.id })
          .then(() => {
            this.calculateProfile()
          })
          .catch(err => {
            this.displayConnectionError = true
            this.retry = true
            this.error = true
            this.spinner = false
            this.$router.push(`/submit-err/${this.$store.state.survey.surveyId}`)
            console.log('error loading profiles', err)
          })
      } else {
        this.sendAnswers()
      }
    },
    goHome () {
      this.$store.commit('CLEAR_FORM')
      this.$router.push('/')
    },
    calculateProfile () {
      // trigger submission when there's no profile
      if (this.profiles && this.profiles.fields && this.profiles.fields.profiles) {
        const tr = this.weightedTestResults

        var profiles = this.profiles.fields.profiles
        var outputProfile = ''
        profiles.forEach((profile, i) => {
          var match = 0
          var minVector = profile.fields.weightedMin.split(',')
          var maxVector = profile.fields.weightedMax.split(',')
          minVector.forEach((variable, j) => {
            if (tr[j] >= minVector[j] && tr[j] <= maxVector[j]) match++
          })

          if (match === minVector.length) {
            outputProfile = Object.assign({}, profile)
          }
        })
        if (outputProfile) {
          this.$store.commit('STORE_PROFILE_IN_SURVEY', {
            profile: outputProfile
          })
          // store profile name in user survey object before sending
        }
      }
      this.sendAnswers()
    },    
    sendAnswers () {
      // Allow only one response if the survey has that parameter set
      if (this.$store.state.survey.allowOnlyOneResponse) {
        var completed = this.$store.state.completed
        if (completed[this.$store.state.survey.surveyId]) {
          var t = completed[this.$store.state.survey.surveyId]
          var now = new Date().getTime()
          //var diff = (now - t) / 1000 / 60 / 60 / 24
          var diff = (now - t) / 1000 / 60 / 60
          if (diff < 1) {
            this.error = true
            this.retry = false

            this.errMessage =  '¡Ya había respondido esta encuesta!'
            if (this.$store.state.survey.language === 'EN') {
              this.errMessage =  'You had already submitted your survey!'
            }
            if (this.$store.state.survey.language === 'PT') {
              this.errMessage =  'You had already submitted your survey!'
            }
            if (this.$store.state.survey.language === 'HIN') {
              this.errMessage =  'आपने पहले ही अपना सर्वेक्षण प्रस्तुत कर दिया था'
            }
            return
          }
        }
      }
  
      this.error = false
      this.spinner = true
      return this.$store.dispatch('SUBMIT_FORM', { that: this })
        .then(data => {
          // do nothing for now
          var survey = this.$store.state.survey
          this.$store.commit('SAVE_PREVIOUS_SURVEY', {
            previousSurveyId: data.data.surveyId,
            previousResponseSetId: data.data._id
          })
          // test if there's one-dimension test result and if there's a threshold
          // then redirect to either of the surveys
          if (survey.threshold && survey.weightedTestResults && survey.weightedTestResults[0]) {
            if (survey.weightedTestResults[0] >= survey.threshold) {
              if (survey.surveyIfAboveThreshold && survey.surveyIfAboveThreshold.sys.id) {
                return this.$router.push(`/s/${survey.surveyIfAboveThreshold.sys.id}`)
              }
            } else {
              if (survey.surveyIfBelowThreshold && survey.surveyIfBelowThreshold.sys.id) {
                return this.$router.push(`/s/${survey.surveyIfBelowThreshold.sys.id}`)
              }
            }
          }
          this.spinner = false
          this.$store.dispatch('THANK_YOU')
          if (this.$store.state.survey.collectPersonalData && this.$store.state.survey.collectPersonalData.sys) {
            this.$router.push({ name: 'collect', params: { cid: this.$store.state.survey.collectPersonalData.sys.id, cqid: 0 } })
          } else {
            this.$router.push({ name: 'results', params: { sid: this.$route.params.sid } })
          }
        })
        .catch(err => {
          this.spinner = false
          this.error = true
          this.retry = true

          if (err.message && err.message.indexOf(400) > -1) {
            this.errMessage =  'Esta encuesta no es válida. Por favor inténtelo en otro momento.'
            if (this.$store.state.survey.language === 'EN') {
              this.errMessage =  'This survey was deemed invalid. Try again later.'
            }
            if (this.$store.state.survey.language === 'PT') {
              this.errMessage =  'This survey was deemed invalid. Try again later.'
            }
            if (this.$store.state.survey.language === 'HIN') {
              this.errMessage =  'इस सर्वेक्षण को अमान्य माना गया था। बाद में पुन: प्रयास करें'
            }
            this.retry = false
            return
          }
          if (err.message && err.message.indexOf(429) > -1) {
            this.errMessage =  'Debe esperar unos minutos antes de enviar esta encuesta.'
            if (this.$store.state.survey.language === 'EN') {
              this.errMessage =  'You need to wait some minutes before submitting another survey.'
            }
            if (this.$store.state.survey.language === 'PT') {
              this.errMessage =  'You need to wait some minutes before submitting another survey.'
            }
            if (this.$store.state.survey.language === 'HIN') {
              this.errMessage =  'आपको एक और सर्वेक्षण प्रस्तुत करने से पहले कुछ मिनट इंतजार करना होगा'
            }
            this.retry = true
            return
          }
          this.errMessage = err
          this.$router.push(`/submit-err/${this.$store.state.survey.surveyId}`)
          this.displayConnectionError = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
.no-connection-banner {
  position: fixed;
  top: -20px;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  background-color: $color-emphasis;
  color: white;
}
.full-area {
  max-width: calc(100vw - 20px);
  &.padded {
    max-width: calc(100vw - 40px) !important;
  }
}
.transition-visibility {
  transition: opacity 0.2s ease-in-out;
}
.invisible {
  opacity: 0.0001;
}
.action-wrapper {
  min-height: 120px;
  position: relative;

  .el-row {
    position: absolute;
    margin: 0 auto;
    width: 100%;
  }
}
</style>
