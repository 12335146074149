<template>
  <div
    class="website-container profile-container appear"
    :class="`show-header-${showAppHeaderInProfile}`"
    v-if="userProfile && userProfile.fields"
  >
    <LocalAppHeader
      :key="Math.random()"
      :hide-menu="true"
      :hide-bubble="true"
      v-if="showAppHeaderInProfile"
    ></LocalAppHeader>

    <div class="profile-container-inner">
      <div
        class="full-areax paddedx vertically-centeredx desktop-wrapperxx inner-wrapper profile-top scrollable"
      >
        <div class="profile-pic-wrapper">
          <div class="icon-wrapper">
            <div
              class="avatar"
              :class="{
                'small-avatar': userProfile.fields.reducedWidthImage,
                'square-avatar': userProfile.fields.squarePic
              }"
              :style="{backgroundImage: `url(${userProfile.fields.avatar.fields.file.url})`}"
              v-if="userProfile.fields.avatar"
            ></div>
          </div>
        </div>
        <div class="title-wrapper">
          <div
            class="hide content-wrap profile-name"
            :class="{'bounce-entry': wellDone }"
          >
            <h4 class="sub" v-if="!userProfile.fields.hideYourProfileIs">{{profileLabelText}}</h4>
            <h1>{{userProfile.fields.name}}</h1>
          </div>
          <div
            v-if="userProfile.fields && userProfile.fields.whatsAppLabel && userProfile.fields.whatsAppApi && (!userProfile.fields.shareUrl && !userProfile.fields.shareCta) || userProfile.fields && userProfile.fields.whatsAppLabel && userProfile.fields.whatsAppApi && (userProfile.fields.shareUrl && userProfile.fields.shareCta && !navigatorIsSupported)"
            @click="openCustomCTA(userProfile.fields.whatsAppApi)"
            class="whatsapp-wrapper unselectable continue-button delay-entry"
          >
            <Icons
              class="icon"
              icon="whatsapp"
              size="32"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
            {{userProfile.fields.whatsAppLabel}}
          </div>

          <div class="navigator-share-wrapper" v-if="navigatorIsSupported && userProfile.fields.shareUrl && userProfile.fields.shareCta">
            <div class="navigator-share-button unselectable" @click="shareTheSurvey(userProfile.fields.shareUrl, userProfile.fields.shareTitle)">
              <Icons
                class="icon"
                icon="share"
                size="32"
                :color="'rgba(255,255,255,0.9)'"
              ></Icons>
              {{userProfile.fields.shareCta}}
            </div>
          </div>

        </div>
      </div>
      <vue-markdown
        class="html-content hide dscx centered-content"
        :class="{'bounce-entry': testResults }"
        v-if="userProfile.fields && userProfile.fields.centeredText"
      >{{useDimensions(addDeviceFingerprint(userProfile.fields.centeredText))}}</vue-markdown>
      <vue-markdown
        class="html-content hide"
        :class="{'bounce-entry': testResults }"
        v-if="userProfile.fields && userProfile.fields.description"
      >{{useDimensions(profileDescription)}}</vue-markdown>
      <div
        class="html-content hide" v-if="userProfile.fields && userProfile.fields.extendedDescription" v-html="extendedDescription"
        :class="{'bounce-entry': testResults }"
      ></div>
      <div
        class="hide"
        :class="{'bounce-entry': testResults }"
      >
      </div>

      <div class="dynamic-statements hide"
        :class="{'bounce-entry': testResults }"
        v-if="dynamicStatements && dynamicStatements.length"
      >
        <div
          class="htmlcontent-wrapper"
          v-for="statement in dynamicStatements"
          v-if="weightedTestResults[statement.fields.index - 1] >= statement.fields.min && weightedTestResults[statement.fields.index - 1] <= statement.fields.max"
        >
          <h4 v-if="statement.fields.scoreText">{{`${statement.fields.scoreText}: ${weightedTestResults[statement.fields.index - 1]}/100`}}</h4>
          <vue-markdown
            class="html-content dynamic-statement"
          >{{statement.fields.description}}</vue-markdown>
        </div>
      </div>

      <div class="action">
        <el-button
          class="hide continue-button"
          :class="{'delay-entry': restartButton }"
          type="danger"
          round
          @click="openCustomCTA($store.state.survey.params.redirect || userProfile.fields.goToUrl)"
          v-if="userProfile.fields.callToAction && userProfile.fields.goToUrl && (userProfile.fields.goToUrl.indexOf('https://') === 0 || userProfile.fields.goToUrl.indexOf('mailto:') === 0)"
        >{{userProfile.fields.callToAction}}</el-button>
        <el-button
          class="hide continue-button"
          :class="{'delay-entry': restartButton }"
          type="danger"
          round
          @click="restartSurvey()"
          v-if="userProfile.fields && userProfile.fields.callToAction && !userProfile.fields.goToUrl"
        >Comenzar de nuevo</el-button>
        <el-button
          class="hide continue-button"
          :class="{'delay-entry': restartButton }"
          type="danger"
          round
          @click="pushHome()"
          v-if="userProfile.fields && userProfile.fields.callToAction && userProfile.fields.goToUrl === '/'"
        >{{userProfile.fields.callToAction}}</el-button>

        <div
          v-if="userProfile.fields && userProfile.fields.googleReviewsLabel && userProfile.fields.googleReviewsLink"
          @click="openCustomCTA(userProfile.fields.googleReviewsLink)"
          class="google-reviews-wrapper hide"
          :class="{'bounce-entry': restartButton }"
        >
          <Icons
            class="icon"
            icon="google"
            size="32"
            :color="'rgba(255,255,255,0.9)'"
          ></Icons>
          {{userProfile.fields.googleReviewsLabel}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

export default {
  props: [ 'bgImage', 'showLocalHeader' ],

  name: 'Profile',

  data () {
    return {
      profile: {},
      wellDone: false,
      yourTime: false,
      testResults: false,
      restartButton: false,
      navigatorIsSupported: false
    }
  },

  components: {
    VueMarkdown,
    LocalAppHeader: () => import('LocalComponents/AppHeader'),
    AppHeader: () => import('Components/web/AppHeader'),
    Icons: () => import('Components/base/Icons')
  },

  computed: {
    profileLabelText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Profile'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Perfil'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'प्रोफ़ाइल'
      }
      return 'Perfil'
    },
    showAppHeaderInProfile () {
      return process.env.VUE_APP_SHOW_APP_HEADER
    },
    customCTA () {
      return this.$store.state.survey.customCTA
    },
    weightedTestResults () {
      return this.$store.state.survey.weightedTestResults
    },
    showTestResults () {
      return this.$store.state.survey.showTestResults
    },
    profileDescription () {
      const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
            `<img src="${fields.file.url}" width="${fields.file.details.image.width}" alt="${fields.description}"/>`,
        },
      };
      if (this.userProfile.fields && this.userProfile.fields.description) {
        return documentToHtmlString(this.userProfile.fields.description, options)
      }
      return ''
    },
    extendedDescription () {
      if (this.userProfile.fields && this.userProfile.fields.extendedDescription) {
        return documentToHtmlString(this.userProfile.fields.extendedDescription)
      }
      return ''
    },
    pid () {
      return this.$route.params.pid
    },
    userProfileId () {
      return this.$store.state.user.profileId
    },
    userProfile () {
      return this.profile || {}
    },
    timeToCompletion () {
      if (this.$store.state.responses && this.$store.state.responses.length) {
        const arr = this.$store.state.responses
        return arr.reduce((accumulator, currentValue) => {
          if (currentValue) {
            return accumulator + currentValue.timeToCompletion
          } else {
            return 0
          }
        }, 0)
      }
      return false
    },
    dynamicStatements () {
      if (this.$store.state.testResults.statements) {
        return this.$store.state.testResults.statements
      }
      return []
    },

  },

  methods: {
    addDeviceFingerprint (text) {
      if (!this.$store.state.fingerprint) return text
      return text.replace(/{{fingerprint}}/g, this.$store.state.fingerprint.slice(-5, this.$store.state.fingerprint.length))
    },
    useDimensions (content) {
      var c = content.replace(/dim\.0/g, this.weightedTestResults[0])
      var c = c.replace("dim.1", this.weightedTestResults[1])
      var c = c.replace("dim.2", this.weightedTestResults[2])
      var c = c.replace("dim.3", this.weightedTestResults[3])
      var c = c.replace("dim.4", this.weightedTestResults[4])
      var c = c.replace("dim.5", this.weightedTestResults[5])
      var c = c.replace("dim.6", this.weightedTestResults[6])
      var c = c.replace("dim.7", this.weightedTestResults[7])
      var c = c.replace("dim.8", this.weightedTestResults[8])
      var c = c.replace("dim.9", this.weightedTestResults[9])
      var c = c.replace("dim.10", this.weightedTestResults[10])
      var c = c.replace("dim.11", this.weightedTestResults[11])
      var c = c.replace("dim.12", this.weightedTestResults[12])
      var c = c.replace("dim.13", this.weightedTestResults[13])
      var c = c.replace("dim.14", this.weightedTestResults[14])
      var c = c.replace("dim.15", this.weightedTestResults[15])
      return c
    },
    shareTheSurvey (url, title) {
      if (navigator.share) {
        navigator.share({
          title: title || document.title,
          url: url || window.location.href,
        }).then(() => {
          console.log('shared')
        }).catch(err => {
          console.log(err)
        })        
      } else {
        console.log('navigator not available for some reason')
      }
    },
    shareUsingNavigator () {

    },
    pushHome () {
      this.$router.push('/')
    },
    openCustomCTA (link) {
      if (link.indexOf('/') === 0 || link.indexOf('https://sensata.io') === 0 || link.indexOf('https://sensata.tk') === 0 || link.indexOf('https://sens.la') === 0 || link.indexOf('https://testeame.com') === 0 || link.indexOf('https://sensataencuestas.com') === 0) {
        window.location.href = link
      } else {
        let l = new URL(link)
        l.searchParams.append('fp', this.$store.state.fingerprint)
        window.open(`${l}`, '_blank')
      }
    },
    restartSurvey () {
      var sid = this.$store.state.survey.surveyId
      this.$store.commit('CLEAR_FORM')
      this.$router.push({ name: 'survey', params: { sid: sid, qid: 0 } })
    },
    loadProfile (pid) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('LOAD_PROFILE_BY_ID', { profileId: pid })
          .then(profile => {
            this.profile = profile
            resolve(profile)
          })
          .catch(err => {
            this.$router.push('/not-found')
            reject(err)
            console.log(err)
          })
      })
    }
  },

  filters: {
    msToMinutes: function (value) {
      const timeInSecs = Math.round(value / 100) / 10
      var mins = Math.floor(timeInSecs / 60)
      var secs = Math.floor(timeInSecs - mins * 60)
      return `${('00' + mins).slice(-2)}:${('00' + secs).slice(-2)}`
    }
  },

  async mounted () {
    var event = new CustomEvent('sensataTestResults', { data: this.weightedTestResults })
    window.parent.postMessage(JSON.stringify({
      type: 'testResults',
      profile: this.pid,
      vector: this.weightedTestResults
    }), '*')

    this.$emit('fullscreen:off')
    // If no pid provided, check if there's a profile in store
    await this.loadProfile(this.pid)

    if (this.$route.query.sid) {
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.$route.query.sid })
        .then(() => {
          this.$store.dispatch('LOAD_TEST_RESULTS', { testResultsId: this.$store.state.survey.testResults.sys.id })          
        })
    }
    if (this.$route.query.vector) {
      var v = this.$route.query.vector.split(',')
      this.$store.commit('STORE_TEST_RESULT', { weightedTestResults: v })
    }

    setTimeout(() => { this.wellDone = true }, 500)
    setTimeout(() => { this.yourTime = true }, 1000)
    setTimeout(() => { this.testResults = true }, 1500)
    setTimeout(() => { this.restartButton = true }, 2000)

    setTimeout(() => {
      if (navigator.share) {
        this.navigatorIsSupported = true
      }
    }, 100)

    if (this.profile.fields.redirectToSurvey) {
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.profile.fields.redirectToSurvey })
      this.$router.push(`/s/${this.profile.fields.redirectToSurvey}/0`)
      return
    }    
  }
}
</script>

<style scoped lang="scss">
.website-container {
  @include breakpoint($tablet) {
    padding-left: calc(50vw - 200px);
    padding-right: calc(50vw - 200px);
    background-color: rgb(252, 252, 239);
  }  
}
.profile-container-inner {
  background: white;
  position: relative;
  z-index: 0;
  padding: 2px 0 20px;
}
.dynamic-statements {
  margin-top: 20px;
  border-top: 1px solid $gray-light;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 20px;

  h4 {
    text-align: left;
    padding: 0 20px;
    margin-bottom: 0;
  }

  .dynamic-statement {
    &.html-content {
      padding-top: 0;
      padding-bottom: 0;
      h2 {
        font-size: 24px !important;
      }
    }    
  }
}
.show-header-true {
  padding-top: 50px !important;
}
.action {
  margin-bottom: 3em;
  z-index: 1000;
}
.whatsapp-wrapper,
.google-reviews-wrapper,
.navigator-share-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(96, 187, 77);
  height: 40px;
  border-radius: 40px;
  color: white;
  width: auto;
  max-width: 80%;
  margin: 1em auto;
  font-size: 17px;
  .icon {
    margin-right: 0.5em;
    padding-top: 0.3em;
  }
}
.google-reviews-wrapper {
  background: rgb(76, 139, 245);
}
.navigator-share-button {
  background-color: $color-emphasis;
}
.avatar {
  width: 80%;
  height: 0;
  padding-bottom: 80%;
  margin-bottom: 1em;
  background-color: $gray-light;
  background-size: cover;
  background-position: top center;

  margin-bottom: 0;
  margin-top: 1em;
  border-radius: 100%;
  border: 1px solid $gray-light;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($tablet) {
    width: 60%;
    padding-bottom: 60%;
  }
  &.square-avatar {
    border-radius: 0;
    margin-top: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}

.icon-wrapper {
  @include breakpoint($micro) {
    margin-bottom: 20px;
  }
}
.inner-wrapper {
}
.title-wrapper {
  margin-bottom: 2em;
  @include breakpoint($tablet) {
    margin-bottom: 0;
  }
}

.hide {
  opacity: 0.0001;
  z-index: -1;
  position: relative;
}
.continue-button {
  margin-top: 20px;

  @include breakpoint($micro) {
    margin-top: 10px;
  }
}

.profile-name {
  margin-top: 1em;
  @include breakpoint($tablet) {
    margin-top: 0;
  }
}

h4.sub {
  color: $color-emphasis;
  margin-bottom: 0;
}
.html-content {
  padding: 0 20px;
  text-align: left;
  font-size: 1.1em;

  &.centered-content {
    background-color: $gray-light;
    padding: 1em 0.5em;
    text-align: center;
  }
}
.gray-text {
  color: $gray-medium;
  font-size: 0.8em;
}
</style>
<style lang="scss">
.dynamic-statements {
  p {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 30px;
  }
}
.website-container.profile-container {
  h1 {
    font-size: 32px;
  }
  h2 {
    color: $color-emphasis !important;
  }
  h3 {
    font-size: 22px;
  }
  .html-content {
    padding-top: 0;
    padding: 0 20px;
    p {
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 17px;
    }
    h2 {
      font-size: 24px !important;
    }
    &.centered-content {
      padding: 10px 20px;
    }
  }
}
</style>

