<template>
  <div
    class="vertically-centered question-wrapper view"
    :class="{ 'scrollable': showTestResults }"
  >
    <DefaultResultsScreen
      v-if="!showTestResults && !customThankYouPage.sys"
      v-on:restart="restartSurvey()"
      v-on:next="restartSurvey()"
    ></DefaultResultsScreen>
    <TwoDimensionalTestResult
      v-if="showTestResults"
      :key="sid"
      v-on:restart="restartSurvey()"
      v-on:next="restartSurvey()"
    ></TwoDimensionalTestResult>
    <Screen
      v-if="!showTestResults && customThankYouPage.sys"
      :key="customThankYouPage.sys.id"
      :data="customThankYouPage"
      :full-width="false"
      v-on:next="restartSurvey()"
      v-on:restart="restartSurvey()"
      v-on:dismiss="redirectToDisplay()"
    ></Screen>
  </div>
</template>

<script>
export default {
  props: [ 'bgImage' ],

  name: 'Results',

  components: {
    Screen: () => import('Components/questions/Screen'),
    DefaultResultsScreen: () => import('Components/base/DefaultResultsScreen'),
    TwoDimensionalTestResult: () => import('Components/base/TwoDimensionalTestResult')
  },

  computed: {
    customThankYouPage () {
      return this.$store.state.survey.customThankYouPage || {}
    },
    weightedTestResults () {
      return this.$store.state.survey.weightedTestResults
    },
    showTestResults () {
      return this.$store.state.survey.showTestResults
    },
    sid () {
      return this.$store.state.survey.surveyId
    }
  },

  mounted () {
    // Emit an event when the render is complete (used by pupeteer/SPAPrerender)
    setTimeout(async () => {
      document.dispatchEvent(new Event('render-completed'))
    }, 1000)

    // If no pid provided, check if there's a profile in store
    if (this.$route.query.sid) {
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.$route.query.sid })
        .then(() => {
          this.$store.dispatch('LOAD_TEST_RESULTS', { testResultsId: this.$store.state.survey.testResults.sys.id })          
        })
    }
    if (this.$route.query.vector) {
      var v = this.$route.query.vector.split(',')
      this.$store.commit('STORE_TEST_RESULT', { weightedTestResults: v })
    }
  },

  methods: {
    restartSurvey () {
      var sid = this.$store.state.survey.surveyId
      this.$store.commit('CLEAR_FORM')
      this.$router.push({ name: 'survey', params: { sid: sid, qid: 0 } })
    },
    redirectToDisplay () {
      var redirectTo = localStorage.getItem('redirect_to_display')

      if (redirectTo) {
        localStorage.removeItem('redirect_to_display')
        return this.$router.push(redirectTo)
      }

      if (did) {
        localStorage.removeItem('redirect_to_display')
        return this.$router.push(`/display/${did}`)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.full-areax {
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
</style>
