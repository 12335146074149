import Vue from 'vue'
var CryptoJS = require("crypto-js")

export default {
  STORE_TRIAL_USER_NICKNAME: (state, { nickname }) => {
    state.trialUser.nickname = nickname
  },
  STORE_TRIAL_USER_ID: (state, { userId }) => {
    state.trialUser.userId = userId
  },
  STORE_LOCAL_RESPONSE: (state, { key, value }) => {
    state.trialUser.more[key] = value
  },
  SAVE_GLOBAL_VAR: (state, { field, value }) => {
    state.globalVars[field] = value
  },
  CLEAR_FRIDGE: (state) => {
    Vue.set(state, 'surveyFridge', {})
    Vue.set(state, 'dataSet', {})
  },
  SET_SURVEY_FRIDGE_DATA: (state, { survey, surveyId }) => {
    Vue.set(state.surveyFridge, surveyId, survey)
  },
  STORE_SURVEY_RESPONSE_OBJECT: (state, { surveyId, responseId }) => {
    Vue.set(state.completedSurveys, surveyId, responseId)
  },
  SAVE_PREVIOUS_SURVEY: (state, { previousSurveyId, previousResponseSetId }) => {
    Vue.set(state.completed, previousSurveyId, new Date().getTime())
    state.survey.previousSurveyId = previousSurveyId
    state.survey.previousResponseSetId = previousResponseSetId
  },
  STORE_FACEBOOK_DATA: (state, { response }) => {
    state.fbData = response
  },
  STORE_FACEBOOK_RESPONSE: (state, { response }) => {
    state.fbAuthResponse = response
  },
  SET_DFP: (state) => {
    if (!state.dfp) {
      state.dfp = btoa(Math.random()).substring(0,24)
    }
  },
  STORE_LOCATION_ITEM: (state, { field, value }) => {
    state.survey.location[field] = value
  },
  STORE_GEOLOCATION: (state, value) => {
    Vue.set(state.survey, 'geolocation', value)
  },
  STORE_GEOLOCATION_ACCURACY: (state, value) => {
    Vue.set(state.survey.geo, 'accuracy', value)
  },  
  STORE_SELF_GEOLOCATION: (state, value) => {
    Vue.set(state.survey, 'selfGeolocation', value)
  },
  STORE_GEOLOCATION_DISTANCE: (state, value) => {
    Vue.set(state.survey.geo, 'distance', value)
  },  
  SET_SURVEY_TOKEN: (state, { token }) => {
    state.survey.token = token
  },  
  SAVE_GOOGLE_RECAPTCHA_TOKEN: (state, { captchaToken }) => {
    state.survey.captchaToken = captchaToken
  },  
  SET_SURVEY_DATA: (state, { survey, surveyId }) => {
    Vue.set(state, 'dataSet', survey.dataSet)
    state.survey.surveyName = survey.surveyName
    state.survey.surveyId = surveyId
    state.survey.allowOnlyOneResponse = survey.allowOnlyOneResponse
    state.survey.customCTA.show = survey.showCustomCta
    state.survey.customCTA.label = survey.customCtaLabel
    state.survey.customCTA.link = survey.customCtaLink
    state.survey.disallowBackButton = survey.disallowBackButton
    state.survey.showTestResults = survey.showTestResults
    state.survey.testResults = survey.testResults
    state.survey.threshold = survey.threshold
    state.survey.surveyIfBelowThreshold = survey.surveyIfBelowThreshold
    state.survey.surveyIfAboveThreshold = survey.surveyIfAboveThreshold
    state.survey.customThankYouPage = survey.thankYouPage
    state.survey.collectPersonalData = survey.collectPersonalData
    state.survey.questionCount = survey.dataSet ? survey.dataSet.length : 0
    state.survey.surveyAlias = survey.surveyAlias
    state.survey.backgroundImage = survey.backgroundImage
    state.survey.language = survey.language
    state.survey.shortcodeTakeThisTest = survey.shortcodeTakeThisTest
    state.survey.hubSpotUser = survey.hubSpotUser
    state.survey.hubSpotForm = survey.hubSpotForm
    state.survey.ogTitle = survey.ogTitle
    state.survey.ogDescription = survey.ogDescription
    state.survey.ogImage = survey.ogImage
    state.survey.storeResponse = survey.storeResponse
    state.survey.noSkipWorkouts = survey.noSkipWorkouts
    state.survey.isPausaActiva = survey.isPausaActiva
    state.survey.requireSurvey = survey.requireSurvey
    state.survey.globalVars = {}
    state.pausaActiva = []
    state.conjoint = []
    state.structuredResponses = {}
    state.survey.collectGeolocation = survey.geolocation
    if (state.responses.length !== state.survey.current) {
      state.structuredResponses = {}
      state.responses = []
      state.survey.current = 0
      state.survey.status = 'started'
    }
  },

  ADMIN_SET_SURVEY: (state, { survey, surveyId }) => {
    Vue.set(state.admin.sidList, surveyId, survey)
    Vue.set(state, 'dataSet', survey.dataSet)
    state.survey.surveyName = survey.surveyName
    state.survey.surveyAlias = survey.surveyAlias
    state.survey.surveyId = surveyId
    state.survey.testResults = survey.testResults
  },

  SET_FINGERPRINT: (state, { fingerprint }) => {
    state.fingerprint = fingerprint
  },

  SAVE_RECENT_SURVEY_SET: (state, { recentSurveySetId }) => {
    state.survey.recentSurveySetId = recentSurveySetId
  },

  DELETE_RECENT_SURVEY_SET: (state, { recentSurveySetId }) => {
    state.survey.recentSurveySetId = ''
  },

  SET_COLLECT: (state, { collect }) => {
    state.survey.collectPersonalData = collect
  },

  ADMIN_SET_SID: (state, { surveyId }) => {
    if (!surveyId) {
      Vue.set(state, 'dataSet', {})
    }
    state.admin.sid = surveyId
  },

  SET_SURVEY_REFS: (state, { data }) => {
    state.surveyRefs = data
  },

  SET_DISPLAY: (state, { data }) => {
    Vue.set(state, 'display', data)
  },

  SET_SITE_LANG: (state, { lang }) => {
    Vue.set(state.site, 'language', lang)
  },

  SET_SITE_DATA: (state, { site, siteId }) => {
    Vue.set(state.site, 'pages', site.pages)

    state.site.content = {}

    // Iterate up to a third level to find the corresponding inner pages
    if (site.webLevelEn) {
      state.site.content[`${site.webLevelEn.fields.alias}`] = site.webLevelEn
      if (site.webLevelEn.fields.webLevel && site.webLevelEn.fields.webLevel.length) {
        site.webLevelEn.fields.webLevel.forEach(level1 => {
          state.site.content[`/${level1.fields.alias}`] = level1
          if (level1.fields.webLevel && level1.fields.webLevel.length) {
            level1.fields.webLevel.forEach(level2 => {
              state.site.content[`/${level1.fields.alias}/${level2.fields.alias}`] = level2
            })
          }
        })
      }
    }

    if (site.webLevelEs) {
      state.site.content[`/es`] = site.webLevelEs
      if (site.webLevelEs.fields.webLevel && site.webLevelEs.fields.webLevel.length) {
        site.webLevelEs.fields.webLevel.forEach(level1 => {
          state.site.content[`/es/${level1.fields.alias}`] = level1
          if (level1.fields.webLevel && level1.fields.webLevel.length) {
            level1.fields.webLevel.forEach(level2 => {
              state.site.content[`/es/${level1.fields.alias}/${level2.fields.alias}`] = level2
            })
          }
        })
      }
    }

    state.site.isLoaded = true
  },

  SET_TEST_RESULTS_DATA: (state, { data }) => {
    state.testResults = data
  },
  STORE_USER_PROFILE: (state, { profile, profileId }) => {
    state.user.profileId = profileId
    state.user.profile = profile
  },
  CLEAR_USER_PROFILE: (state) => {
    state.user = {}
  },
  BROWSER_REPORT: (state, { report }) => {
    state.survey.browserReport = report
  },
  STORE_RESPONSE: (state, { qid, response }) => {
    state.responses[qid] = response
  },
  STORE_NEW_CONJOINT: (state, { iteration, conjoint }) => {
    state.conjoint.push(conjoint)
  },
  STORE_RANDOM_GROUP_NAVIGATOR: (state, { qid, rgqid }) => {
    Vue.set(state.randomGroupsNavigator, qid, rgqid)
  },
  STORE_RANDOM_GROUP_QID: (state, { qid, children }) => {
    Vue.set(state.randomGroups, qid, children)
  },
  CLEAR_RANDOM_GROUPS: (state) => {
    state.randomGroups = {}
    state.randomGroupsNavigator = {}
  },
  ALTER_WEIGHTED_TEST_RESULTS: (state) => {
    state.responses.forEach(response => {
      if (response.saveToDimension) {
        state.survey.weightedTestResults[response.saveToDimension] = response.selected[0]
      }
    })
  },

  
  STORE_RESPONSE_NEW: (state, { qid, response }) => {
    let newResponse = {...response}
    state.responses[qid] = response
    if (response && response.selected || response.terms) {
      let questionData = response.questionData ? response.questionData : state.dataSet[state.survey.qid]

      newResponse.qid = response.qid || qid
      newResponse['maxResponses'] = questionData.fields.validAnswers || 1
      newResponse['isSorting'] = questionData.fields.displaySelectionOrder || false
      newResponse['isOrdered'] = ['slider', 'emojiSlider', 'emojiBubbles', 'likert', 'NPS', 'rating'].indexOf(response.type) > -1 ? true : false
      newResponse['altOption'] = questionData.fields.altOption || 'NA'

      if (!isNaN(response.randomGroupOrder)) {
        newResponse['isRandomGroupChild'] = true
      }

      // Add options so we can create the dictionary automatically
      var options = []
      if (response.type === 'slider' || response.type === 'emojiSlider') {
        var steps = parseInt(questionData.fields.steps, 10) || parseInt(questionData.fields.step, 10)  || 1
        if (response.numberOfOptions / steps <= 30) {
          for (var i = questionData.fields.from; i <= questionData.fields.to; i += steps) {
            options.push(i.toString())
          }
          if (questionData.fields.textScale) {
            options[0] = `${options[0]}. ${questionData.fields.textScale[0]}`
            options[options.length - 1] = `${options[options.length - 1]}. ${questionData.fields.textScale[1]}`
          }
        } else {
          options = [`${questionData.fields.from} - ${questionData.fields.to}`]
        }
        newResponse['numberOfOptions'] = response.numberOfOptions
      }
      if (response.type === 'traditionalQuestion' && questionData.fields.options) {
        options = questionData.fields.options
      }
      if (response.type === 'thumbs') {
        options = ['down', 'up']
      }
      if (response.type === 'rating') {
        for (var i = 1; i <= 5; i++) {
          options.push(i.toString())
        }
      }
      if (response.type === 'likert' || response.type === 'emojiBubbles') {
        options = []
        var steps = response.numberOfOptions
        for (var i = 1; i <= steps; i++) {
          options.push(i.toString())
        }
        if (response.textScale) {
          options[0] = `${options[0]}. ${response.textScale[0]}`
          options[steps - 1] = `${options[steps - 1]}. ${response.textScale[1]}`
        }
      }
      if (response.type === 'NPS') {
        var option = ''
        for (var i = 0; i <= 10; i++) {
          options.push(i.toString())
        }
        if (questionData.fields.textScale) {
          options[0] = `${options[0]}. ${questionData.fields.textScale[0]}`
          options[10] = `${options[10]}. ${questionData.fields.textScale[1]}`
        }
      }
      if (response.type === 'bilderset') {
        response.optionsImages.forEach((image, index) => {
          var option = image.fields.title
          if (response.textOptions) {
            option += `//${response.textOptions[index]}`
          }
          options.push(option)
        })
      }
      if (response.type === 'emojiSlider') {
        var steps = questionData.fields.steps
        for (var i = 1; i <= steps; i++) {
          options.push(i.toString())
        }
        if (questionData.fields.fromEmoji) {
          options[0] = `${options[0]}. ${questionData.fields.fromEmoji.fields.title}`
          options[steps - 1] = `${options[steps - 1]}. ${questionData.fields.toEmoji.fields.title}`
        }
      }

      if (response.type !== 'slider') {
        newResponse['numberOfOptions'] = options.length
      }

      // if it's likert, do something special
      if (response.from && response.to) {
        var diff = response.to - response.from
        newResponse['n_selected'] = parseInt(response.selected[0], 10)
        newResponse['n_0-100'] = ( response.selected[0] - 1 ) * 100 / diff
        newResponse['n_centered'] = ( response.selected[0] - 1 ) * 100 / diff - 50
      }

      if (response.splitSelected) {
        newResponse['splitSelected'] = response.splitSelected
      }
      if (response.labelSelection) {
        newResponse['labelSelection'] = response.labelSelection
      }
      if (response.type === 'screen' || response.type === 'emoji' || response.type === 'multiTextSelection' || response.type === 'dilemma') {
        options = response.options
      }
      if (response.type === 'multiTextSelection' || response.type === 'dilemma') {
        newResponse['numberOfOptions'] = newResponse.options.length
      }
      newResponse.options = options
      newResponse.selectedS = newResponse.selected.join('/')
      state.structuredResponses[response.identifier] = newResponse
      // end of script: assign for old newResponses
      state.newResponses[qid] = newResponse
    }
  },
  SET_CURRENT: (state, { current }) => {
    state.survey.current = current
  },
  SET_ACTUAL_QID: (state, { qid }) => {
    state.survey.qid = qid
  },
  SET_SURVEY_STATUS: (state, { status }) => {
    state.survey.status = status
    if (status !== 'started') {
      state.survey.current = 0
    }
  },
  CLEAR_RESPONSES: (state) => {
    state.responses = []
    state.newResponses = {}
    state.survey.status = 'sent'
  },
  CLEAR_FORM: (state) => {
    state.responses = []
    state.newResponses = {}
    state.survey.current = null
    state.survey.status = 'started'
    state.survey.surveyId = null
    state.survey.current = 0
    state.survey.qid = 0
    state.survey.token = 'koku'
    state.survey.captchaToken = ''
    state.survey.globalVars = {}
  },
  CLEAR_FRIDGE: (state) => {
    state.surveyFridge = {}
  },
  RESTART_FORM: (state) => {
    state.responses = []
    state.newResponses = {}
    state.survey.current = 0
    state.survey.status = 'started'
    state.survey.globalVars = {}
  },
  STORE_TEST_RESULT: (state, { weightedTestResults }) => {
    state.survey.weightedTestResults = weightedTestResults
  },
  STORE_PROFILE_IN_SURVEY: (state, { profile }) => {
    state.survey.profile = profile.fields.name
  },
  STORE_REQUEST_PARAMS: (state, { params }) => {
    state.survey.params = params
  },
  /*
   * Auth0 stuff
   */
  AUTH0_SET_USER: (state, user) => {
    if (user.user && user.user.sub) {
      Vue.set(state.user, 'auth0', user.user)
      Vue.set(state.user, 'loggedIn', true)
    } else {
      Vue.set(state.user, 'auth0', {})
      Vue.set(state.user, 'loggedIn', false)
    }
  },
  // This shouldn't be stored in localStorage
  SAVE_TOKEN: (state, { accessToken, idToken }) => {
    Vue.set(state.user.token, 'accessToken', accessToken)
    Vue.set(state.user.token, 'idToken', idToken)
  },
  LOG_OFF: (state) => {
    Vue.set(state.user, 'auth0', {})
    Vue.set(state.user, 'tokenData', {})
    state.user.loggedIn = false
  },
  /* ADMIN PLAY STUFF */
  SET_ADMIN_STATE: (state, { status }) => {
    state.admin.play.status = status
    state.admin.play.startTimestamp = new Date().getTime()
  },
  SET_ANALYZE_START_TIMESTAMP: (state, { timestamp }) => {
    state.admin.play.startTimestamp = timestamp
  },
  SET_ANALYZE_END_TIMESTAMP: (state, { timestamp }) => {
    state.admin.play.endTimestamp = timestamp
  }

}
