import api from 'Core/src/api/index'
// TODO
// Import router using a different strategy
import router from '../../../router'

export default {
  /**
   * Request a token from the backend
   */
  REQUEST_SURVEY_TOKEN: ({ commit, dispatch, state }, { surveyId, fingerprint }) => {
    return new Promise((resolve, reject) => {
      return api.requestSurveyToken({ surveyId: surveyId, fingerprint: fingerprint, surveyLength: state.survey.questionCount })
        .then(data => {
          commit('SET_SURVEY_TOKEN', { token: data.data })
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  /**
   * Fetch the site. Uses the API to resolve the question data
   */
  LOAD_NEW_SITE: ({ commit, dispatch, state }, { siteId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchSiteFull({ siteId: siteId })
        .then(data => {
          commit('SET_SITE_DATA', { site: data, siteId: siteId })
          return resolve(data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  },

  /**
   * Fetch the survey. Uses the API to resolve the question data
   */
  LOAD_NEW_SURVEY: ({ commit, dispatch, state }, { surveyId, force }) => {
    return new Promise((resolve, reject) => {
      commit('CLEAR_FORM')
      // First see if it's in the fridge — commented out as it's not working perfectly
      /*
      if (process.env.VUE_APP_ENABLE_CACHING && state.surveyFridge[surveyId] && state.surveyFridge[surveyId].dataSet && state.surveyFridge[surveyId].dataSet.length && !force) {
        commit('CLEAR_FORM')
        commit('SET_SURVEY_DATA', { survey: state.surveyFridge[surveyId], surveyId: surveyId })
        return resolve()
      }
      */
      return api.fetchSurvey({ surveyId: surveyId })
        .then(data => {
          commit('SET_SURVEY_FRIDGE_DATA', { survey: data, surveyId: surveyId })
          commit('SET_SURVEY_DATA', { survey: state.surveyFridge[surveyId], surveyId: surveyId })
          return resolve()
        })
        .catch(err => {
          return reject(err)
        })
    })
  },

  /**
   * Fetch the survey. Uses the API to resolve the question data
   */
  RELOAD_SURVEY: ({ commit, dispatch, state }, { surveyId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchSurvey({ surveyId: surveyId })
        .then(data => {
          commit('SET_SURVEY_FRIDGE_DATA', { survey: data, surveyId: surveyId })
          commit('SET_SURVEY_DATA', { survey: state.surveyFridge[surveyId], surveyId: surveyId })
          return resolve()
        })
        .catch(err => {
          return reject(err)
        })
    })
  },

  /**
   * Load any entry. Uses the API to resolve the question data
   */
  LOAD_ENTRY: ({ commit, dispatch, state }, { entryId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchSurvey({ surveyId: entryId })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  /**
   * Fetch the survey. Uses the API to resolve the question data
   */
  LOAD_SURVEY_REFS: ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
      return api.fetchSurveyRefs()
        .then(data => {
          commit('SET_SURVEY_REFS', { data: data })
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  /**
   * Fetch the survey. Uses the API to resolve the question data
   */
  LOAD_NEW_DISPLAY: ({ commit, dispatch, state }, { displayId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchSurvey({ surveyId: displayId })
        .then(data => {
          commit('SET_DISPLAY', { data: data })
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  /**
   * Fetch the survey. Uses the API to resolve the question data
   */
  ADMIN_LOAD_SID: ({ commit, dispatch, state }, { surveyId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchSurvey({ surveyId: surveyId })
        .then(data => {
          commit('ADMIN_SET_SURVEY', { survey: data, surveyId: surveyId })
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  /**
   * Fetch the test results. Uses the API to resolve the question data
   */
  LOAD_TEST_RESULTS: ({ commit, dispatch, state }, { testResultsId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchTestResults({ testResultsId: testResultsId })
        .then(data => {
          commit('SET_TEST_RESULTS_DATA', { data: data })
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  /**
   * Fetch the profile. Uses the API to resolve the question data
   */
  LOAD_PROFILE_BY_ID: ({ commit, dispatch, state }, { profileId }) => {
    return new Promise((resolve, reject) => {
      return api.fetchProfile({ profileId: profileId })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  /**
   * Restart the form responses/status.
   */
  RESTART_FORM: ({ commit, dispatch, state }) => {
    commit('RESTART_FORM')
  },
  /**
   * Store a response locally.
   */
  STORE_RESPONSE: ({ commit, dispatch, state }, { response }) => {
    var qid = parseInt(router.currentRoute.params.qid, 10)
    commit('STORE_RESPONSE', { qid: qid, response: response })
  },
  STORE_RESPONSE_NEW: ({ commit, dispatch, state }, { qid, response }) => {
    commit('STORE_RESPONSE_NEW', { qid: qid, response: response })
  },
  SUBMIT_TO_ALT_TRACKING_DB: ({ commit, dispatch, state }, { response }) => {
    return new Promise((resolve, reject) => {
      return api.sendResponseToAltDB({ response: response, params: state.survey.params })
        .then(() => {
          return resolve()
        })
        .catch(err => {
          return reject(err)
        })
    })
  },
  THANK_YOU: ({ commit, dispatch, state }) => {
    commit('SET_SURVEY_STATUS', { status: 'sent' })
    commit('CLEAR_FORM')
  },
  /**
   * Submits gathered responses using the api
   */
  SUBMIT_FORM: ({ commit, dispatch, state }, { that }) => {
    return new Promise((resolve, reject) => {
      dispatch('REQUEST_GOOGLE_CAPTCHA', { that: that })
        .then(() => {
          commit('SET_SURVEY_STATUS', { status: 'submit' })
          if (state.survey.hubSpotUser && state.survey.hubSpotForm) {
            api.sendHubSpotForm({ responses: state.responses, surveyData: state.survey })
              .then(data => {
                return
              })
              .catch(err => {
                console.log('hubspot err:', err)
              })      
          }

          return api.sendResponse({ responses: state.responses, surveyData: state.survey, state: state })
            .then(data => {
              commit('STORE_SURVEY_RESPONSE_OBJECT', { surveyId: state.survey.surveyId, responseId: data.data.responseId })
              commit('SAVE_RECENT_SURVEY_SET', { recentSurveySetId: data.data.responseId })
              // Now clear responses from local Storage
              return resolve(data)
            })
            .catch(err => {
              return reject(err)
            })
        })
        .catch(err => {
          console.log('rejected', err)
          return reject(err)
        })
      })
  },
  /**
   * Identifies a responseSet with a userId
   */
  IDENTIFY_EXISTING_USER: ({ commit, dispatch, state }, { phoneId, survey, fingerprint }) => {
    return new Promise((resolve, reject) => {
      return api.identifyExistingUser({ phoneId: phoneId, recentSurveySetId: survey.recentSurveySetId, fingerprint: fingerprint, surveyId: survey.surveyId })
        .then(data => {
          // Now clear responses from local Storage
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  /**
   * Registers a user
   */
  REGISTER_USER: ({ commit, dispatch, state }, { data, survey, fingerprint }) => {
    commit('SET_SURVEY_STATUS', { status: 'submit' })
    return new Promise((resolve, reject) => {
      return api.registerNewUser({
        data: data,
        survey: survey,
        fingerprint: fingerprint
      })
        .then(data => {
          // Now clear responses from local Storage
          console.log('api then: ', data)
          return resolve(data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  },
  /**
   * Request Google Captcha
   */
  REQUEST_GOOGLE_CAPTCHA: ({ commit, dispatch, state }, { that }) => {
    return new Promise(async (resolve, reject) => {
      if (process.env.VUE_APP_BASE_URL === 'https://sensata.tk') {
        commit('SAVE_GOOGLE_RECAPTCHA_TOKEN', { captchaToken: 'koku-tk' })
        return resolve('koku-tk')
      }
      try {
        const captchaToken = await that.$recaptcha('nextQuestion')
        commit('SAVE_GOOGLE_RECAPTCHA_TOKEN', { captchaToken: captchaToken })
        return resolve(captchaToken)
      } catch (err) {
        commit('SAVE_GOOGLE_RECAPTCHA_TOKEN', { captchaToken: 'koku-tk-recaptcha-not-working' })
        return resolve('koku-tk-recaptcha-not-working')
        // reject(err)
      }
    })
  },
  /** 
   * Upload file to S3 
   */
  UPLOAD_FILE_TO_S3: ({ commit, state }, { file, bucketName, key, contentType }) => {
    return new Promise((resolve, reject) => {
      return api.uploadToS3(file, bucketName, key, contentType)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
},

  /**
   * Move to the next question if possible
   */
  NEXT_QUESTION: ({ commit, dispatch, state }, { skip }) => {
    var current = parseInt(state.survey.qid, 10)
    if (current <= state.survey.questionCount - 1 - skip) {
      commit('SET_CURRENT', { current: current + 1 + skip})
    } else {
      // Survey was completed
      commit('SET_SURVEY_STATUS', { status: 'submit' })
    }
  },

  /**
   * Calculate the max and the min for each question according to the available options
   */
  CALCULATE_WEIGHTED_QUESTION: ({ commit, dispatch, state }, { formula, selected, options, maxAnswers, skip }) => {
    return new Promise((resolve, reject) => {
      // A little helper to find the index of options with images
      function findTitleField (array, value) {
        for (var i = 0; i < array.length; i += 1) {
          if (array[i].fields.title === value) {
            return i
          }
        }
        return -1
      }
      // Create an array to hold the final calculation
      var weighted = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var weightedMax = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var weightedMin = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      var feedback = ''

      // calculate the skip only if there's one question
      var newSkip = 0
      if (selected.length === 1) {
        if (skip && skip.length) {
          var skipArray = skip.split(',')
          if (skipArray.length === options.length + 1) {
            options.forEach((option, index) => {
              if (option === selected[0]) {
                newSkip = skipArray[index]
                return
              }
              if ('S99' === selected[0]) {
                newSkip = skipArray[skipArray.length - 1]                
                return
              }
              if (option && option.fields && option.fields.title === selected[0]) {
                newSkip = skipArray[index]
                return
              }
            })
          }
        }
      }

      if (!formula) {
        return resolve({ weighted, weightedMax, weightedMin, feedback, newSkip })
      }
      selected.forEach(item => {
        var index = options.indexOf(item)

        // Options might be images, so we have to prepare them
        if (typeof options === 'object' && options[0].hasOwnProperty('sys')) {
          index = findTitleField(options, item)
        }
        // Transform the formula into an array and clean it (remove variable names and empty slots)
        if (index === -1) {
          return
        }

        if (formula && formula[index]) {
          var arr = formula[index].split(/[,]+/).filter(i => i)
          if (index > -1) {
            weighted.forEach((variable, i) => {
              weighted[i] += !isNaN(arr[i]) ? parseInt(arr[i], 10) : 0
            })
          }
        }
      })

      // Get the maximum and minimum according to the formula

      // Get an array of formulas, cleaned
      var formulas = []
      formula.forEach(item => {
        var arr = item.split(/[,]+/).filter(i => i)
        formulas.push(arr)
      })

      // Traverse the formulas
      var traverse = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
      formulas.forEach((vector, i) => {
        vector.forEach((factor, j) => {
          traverse[j][i] = factor
        })
      })

      // Calculate the maximum as a vector
      // Sort the traversed formulas
      traverse.forEach((trav, index) => {
        trav.sort((a, b) => {
          return parseInt(b, 10) - parseInt(a, 10)
        })
      })
      traverse.forEach((values, i) => {
        for (var j = 0; j < maxAnswers; j++) {
          var wMaxi = parseInt(weightedMax[i] || 0)
          weightedMax[i] = wMaxi + parseInt(values[j], 10) > wMaxi ? wMaxi + parseInt(values[j], 10) : wMaxi
        }
      })

      // Calculate the minimum as a vector
      // Sort the traversed formulas
      traverse.forEach((trav, index) => {
        trav.sort((a, b) => {
          return parseInt(a, 10) - parseInt(b, 10)
        })
      })
      traverse.forEach((values, i) => {
        for (var j = 0; j < maxAnswers; j++) {
          var wMini = parseInt(weightedMin[i] || 0)
          weightedMin[i] = wMini + parseInt(values[j], 10) < wMini ? wMini + parseInt(values[j], 10) : wMini
        }
      })

      if (JSON.stringify(weighted) === JSON.stringify(weightedMax)) {
        feedback = 'correct'
      } else {
        feedback = 'wrong'
      }

      return resolve({ weighted, weightedMin, weightedMax, feedback, newSkip })
    })
  }
}
