<template>
  <div class="website-external-wrapper">
    <div class="full-area padded vertically-centered" v-if="!canForward">
      <SensataSplash></SensataSplash>
    </div>
    <AppHeader
      :data="siteContent"
      :slug="slug"
      v-if="canForward"
      :key="'app-header' + slug"
    ></AppHeader>

    <WebContainer
      :data="siteContent"
      :slug="slug"
      :key="Math.random() + slug"
    ></WebContainer>

    <div class="powered-by-wrapper">
      <a href="https://www.contentful.com/" rel="nofollow" target="_blank" class="powered-item"><img src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg" style="max-width:100px;width:100%;" alt="Powered by Contentful" /></a>
      <div class="small-text">
        This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.          
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',

  transition: 'page',

  components: {
    AppHeader: () => import('Components/web/AppHeader'),
    WebContainer: () => import('Components/web/WebContainer'),
    SensataSplash: () => import('Components/base/SensataSplash')
  },

  data () {
    return {
      canForward: true
    }
  },

  computed: {
    siteContent () {
      return this.$store.state.site.content
    },
    pages () {
      return this.$store.state.site.pages
    },
    sensataShowSplash () {
      return process.env.VUE_APP_SHOW_SENSATA_SPLASH
    },
    sid () {
      return process.env.VUE_APP_DEFAULT_SID
    },
    totalPages () {
      var allPages = Object.keys(this.siteContent)
      return allPages.length
    },
    slug () {
      var root = '/'
      var path = this.$route.params.path
      var subpath = this.$route.params.subpath
      if (path) {
        root = root + this.$route.params.path        
      }
      if (subpath) {
        return root + '/' + subpath
      }
      return root
    }
  },

  methods: {
    /*
     * If there's at least one page, redirect there automatically
     */
    pushSite () {
      if (!this.canForward || this.siteContent) {
        return
      }
      if (this.pages && this.pages.length && this.canForward) {
        this.$router.push({ name: 'page', params: { alias: this.pages[0].fields.alias } }).catch(err => {})
        this.$router.push(`/p/${this.pages[0].fields.alias}`).catch(err => {})
      } else {
        if (this.sid) {
          this.$router.push({ name: 'survey', params: { sid: this.sid, qid: 0 } }).catch(err => {})
        }
      }
    }
  },

  watch: {
    'pages': function (newVal, oldVal) {
      if (newVal && newVal.length) {
        this.pushSite()
      }
    },
    'canForward': function (newVal, oldVal) {
      if (newVal) {
        this.pushSite()
      }
    }
  },
  metaInfo () {
    var ogTitle = ''
    var ogDescription = ''
    var ogImage = ''

    var a = this.siteContent[window.location.pathname]
    if (a && a.fields) {
      ogTitle = a.fields.ogTitle
      ogDescription = a.fields.ogDescription
      if (a.fields.ogImage) {
        ogImage = a.fields.ogImage.fields.file.url
      }
    }
    return {
      title: ogTitle,
      meta: [
        { vmid: "og:url", property: "og:url", content: `${process.env.VUE_APP_BASE_URL}` },
        { vmid: "description", name: "description", content: ogDescription },
        { vmid: "og:title", property: "og:title", content: ogTitle },
        { vmid: "og:description", property: "og:description", content: ogDescription },
        { vmid: "twitter:card", property: "twitter:card", content: "summary_large_image" },
        { vmid: "og:image:width", property: "og:image:width", content: "1200" },
        { vmid: "og:image:height", property: "og:image:height", content: "1200" },
        { vmid: "og:image", property: "og:image", content: 'https:' + ogImage }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.powered-by-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: $gray-light;
  flex-wrap: wrap;
}
.small-text {
  margin-top: 1em;
  font-size: 12px;
  text-align: left;
  @include breakpoint($tablet) {
    text-align: right;
    max-width: 300px;
  }
}

</style>
