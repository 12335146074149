import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'Core/src/store/index'
import './registerServiceWorker'
import './plugins/element.js'
import fullscreen from 'vue-fullscreen'
import VueDND from 'awe-dnd'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner, faAlignLeft, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import Vue2TouchEvents from 'vue2-touch-events'
import VueAnalytics from 'vue-analytics'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import 'Core/src/Filters'
import VueMobileDetection from "vue-mobile-detection";
import VueMeta from 'vue-meta'
import { VueReCaptcha } from 'vue-recaptcha-v3'
 
// For more options see below
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_PUBLIC_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
})

library.add(faSpinner, faAlignLeft, faThumbsUp, faThumbsDown)

const VueInputMask = require('vue-inputmask').default
 
Vue.use(VueInputMask)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueDND)
Vue.config.productionTip = false
Vue.use(fullscreen)
Vue.use(VueMobileDetection)
Vue.use(Vue2TouchEvents, {
  swipeTolerance: 90
})
Vue.use(VueQrcode)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_UA_CODE,
    checkDuplicatedScript: true,
    router
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
